var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Code, Divider, Heading, Image, Link, ListItem, OrderedList, Table, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { BLOCK_CSS } from '@/types';
export const MarkdownBase = (_a) => {
    var { markdown = '' } = _a, rest = __rest(_a, ["markdown"]);
    const renderMarkdownComponents = () => {
        const components = {
            code: Code,
            hr: Divider,
            img: Image,
            li: ListItem,
            ol: OrderedList,
            table: Table,
            tbody: Tbody,
            td: Td,
            th: Th,
            thead: Thead,
            tr: Tr,
            ul: UnorderedList,
            a: (props) => (_jsx(Link, Object.assign({}, props, { color: "lightBlue.60", target: "_blank", textDecoration: "underline !important" }))),
            b: (props) => _jsx(Text, Object.assign({}, props, { as: "b" })),
            blockquote: (props) => _jsx(Code, Object.assign({}, props, { as: "blockquote" })),
            del: (props) => _jsx(Text, Object.assign({}, props, { as: "del" })),
            em: (props) => _jsx(Text, Object.assign({}, props, { as: "em" })),
            i: (props) => _jsx(Text, Object.assign({}, props, { as: "i" })),
            p: (props) => _jsx(Text, Object.assign({}, props, { as: "p" })),
            pre: (props) => _jsx(Code, Object.assign({}, props, { as: "pre" })),
            text: (props) => _jsx(Text, Object.assign({}, props, { as: "span" })),
        };
        for (const [h, size] of Object.entries(BLOCK_CSS.HeadingSize)) {
            components[h] = (props) => (_jsx(Heading, Object.assign({ as: h, fontSize: size }, props)));
        }
        return components;
    };
    const formatMarkdown = (text) => {
        const formatters = [{ pattern: /\+\+(.*?)\+\+/gm, replacement: '<u>$1</u>' }];
        return formatters.reduce((a, f) => a.replace(f.pattern, f.replacement), text);
    };
    const markdownData = formatMarkdown(markdown);
    const components = renderMarkdownComponents();
    return (_jsx(Box, Object.assign({}, rest, { children: _jsx(ReactMarkdown, Object.assign({ components: components, rehypePlugins: [rehypeRaw], remarkPlugins: [remarkGfm] }, { children: markdownData })) })));
};
